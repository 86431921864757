const CompanyFields = {
  state: {
    fields: [],
  },
  reducers: {
    // To change the state fields
    setFields: (state, payload) => {
      return {
        ...state,
        fields: payload,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default CompanyFields;
