import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { LocationsServices } from "../../services";

export default function Cancel({
  createdMarker,
  createdPolygon,
  deletedMultiplePointsBoundary,
}) {
  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );
  const currentSelectedFieldId = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldId
  );
  const dispatch = useDispatch();
  const currentLocationNumber = useSelector(
    (state) => state.CreatePointandPolygon.currentLocationNumber
  );
  const editedPoint = useSelector(
    (state) => state.CreatePointandPolygon.editedPoint
  );
  const editedPolygon = useSelector(
    (state) => state.CreatePointandPolygon.editedPolygon
  );
  const deletedPoint = useSelector(
    (state) => state.CreatePointandPolygon.deletedPoint
  );
  const deletedPolygon = useSelector(
    (state) => state.CreatePointandPolygon.deletedPolygon
  );

  const handlePolygonCancelButton = () => {
    if (createdMarker) {
      createdMarker.setMap(null);
    }
    if (createdPolygon) {
      createdPolygon.setMap(null);
    }
    if (deletedMultiplePointsBoundary) {
      deletedMultiplePointsBoundary.setMap(null);
    }
    if (editedPoint || currentLocationNumber) {
      dispatch.CreatePointandPolygon.setEditedPoint(null);
      LocationsServices.getAllCoordinatesOfAfields(currentSelectedFieldUID)
        .then((res) => {
          const fieldCoords = res.data.locations;
          const fieldCoords_updated = fieldCoords.map((item) => {
            return {
              ...item,
              editable: false,
              deleteMode: false,
              editMode: false,
            };
          });
          if (fieldCoords_updated.length > 0) {
            dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    if (editedPolygon) {
      dispatch.CreatePointandPolygon.setEditedPolygon(null);
      dispatch.DisplaySettings.toggleLocationNumber(false);
      LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
        .then((res) => {
          const polygonGeoms = res.data.map((item) => {
            return {
              ...item,
              deleteMode: false,
              editable: false,
              editMode: false,
            };
          });
          if (polygonGeoms.length > 0) {
            dispatch.FieldDetails.setSelectedFieldPolygonGeoms(polygonGeoms);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    if (deletedPoint) {
      dispatch.CreatePointandPolygon.setDeletedPoint(null);
      LocationsServices.getAllCoordinatesOfAfields(currentSelectedFieldUID)
        .then((res) => {
          const fieldCoords = res.data.locations;
          const fieldCoords_updated = fieldCoords.map((item) => {
            return {
              ...item,
              editable: false,
              deleteMode: false,
              editMode: false,
            };
          });
          if (fieldCoords_updated.length > 0) {
            dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    if (deletedPolygon) {
      dispatch.CreatePointandPolygon.setDeletedPolygon(null);
      LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
        .then((res) => {
          const polygonGeoms = res.data.map((item) => {
            return {
              ...item,
              deleteMode: false,
              editable: false,
              editMode: false,
            };
          });
          if (polygonGeoms.length > 0) {
            dispatch.FieldDetails.setSelectedFieldPolygonGeoms(polygonGeoms);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
    dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
  };
  return (
    <Tooltip title="Cancel">
      <IconButton
        onClick={handlePolygonCancelButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Cancel"
      >
        <CancelIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
