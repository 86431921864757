import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Save from "./Save";
import Cancel from "./Cancel";
import "./Grouping.css";

export default function BasicButtonGroup({
  display,
  createdMarker,
  createdPolygon,
  deletedMultiplePointsBoundary,
}) {
  return (
    <ButtonGroup
      sx={{ display: display ? "block" : "none" }}
      className="grouping-edit-events"
      variant="contained"
      aria-label="outlined primary button group"
    >
      <Save
        createdMarker={createdMarker}
        createdPolygon={createdPolygon}
        deletedMultiplePointsBoundary={deletedMultiplePointsBoundary}
      />
      <Cancel
        createdMarker={createdMarker}
        createdPolygon={createdPolygon}
        deletedMultiplePointsBoundary={deletedMultiplePointsBoundary}
      />
    </ButtonGroup>
  );
}
