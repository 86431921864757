import React from "react";
import CircleLoader from "react-spinners/BounceLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Spinner(props) {
  return (
    <div className="sweet-loading">
      <CircleLoader
        color={props.color}
        loading={true}
        cssOverride={override}
        size={props.size}
        className={props.className}
      />
    </div>
  );
}
export default Spinner;
