import React, { useEffect, useState,Component } from "react";
import { useDispatch, useSelector } from "react-redux";
const { forwardRef, useRef, useImperativeHandle } = React;

class LayerLegend extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    //if (nextProps.maxValue !== this.state.maxValue) {
      
    //}
  }
  updateColors(columnType){
    
  }
  componentDidMount() {
    
  }

  render() {
    let colors = ['rgba(255,0,0,1)' ,'rgba(255,145,0,1)', 'rgba(255,252,0,1)' ,'rgba(0,230,131,1)', 'rgba(1,255,201,1)', 'rgba(126,233,255,1)','rgb(221 246 252)'];// rgba(252,252,252,1) 100%);
    //colors = colors.reverse();
    let inc_ = parseInt((this.props.maxValue-this.props.minValue)/7);
    let counter = 0;
    return (
        <table style={{
            width:'100%',
            tableLayout: 'fixed',
            overflowWrap: 'break-word',
            height:'25px'
            }}>
            <tr>
            <td><input type="number" style={{ border: '1px solid black',
                    fontSize:'12px',
                      float: 'right',
                      width: '25px'}}/></td>
              {
                  colors.map(function(color) {
                      return <td style={{background:color}}></td>   
                  })
              }
              <td><input type="number" style={{ border: '1px solid black',
                    fontSize:'12px',
                      float: 'right',
                      width: '25px'}}/></td>
            </tr>
            
        </table>
  );
}
}

export default LayerLegend;

