import React, { useState, useEffect } from "react";
import "./Map.css";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";

const libraries = ["places", "drawing"];

// Custom map control to switch between basemap options

const Map = ({ zoom, center, onMap }) => {
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [selectedOption, setSelectedOption] = useState("satellite");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA-LaWEor4ckZWutoufU3y6ou4Sww4EpdE",
    libraries,
  });

  //map instance passing to the parent
  useEffect(() => {
    if (!map) return;

    if (onMap) {
      onMap(map);
    }
  }, [map, onMap]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionClick = (event) => {
    setSelectedOption(event.target.value);
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      {" "}
      <GoogleMap
        center={center}
        mapContainerClassName="google-map"
        zoom={zoom}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          mapTypeId: selectedOption,
          draggableCursor: "crosshair",
        }}
        onLoad={(map) => setMap(map)}
        tilt={0}
      ></GoogleMap>
      <div
        style={{
          position: "absolute",
          bottom: "8px",
          marginLeft: "10px",
          backgroundColor: "#e3ede9",
        }}
      >
        Basemap:
        <div>
          <select value={selectedOption} onChange={handleSelectChange}>
            <option onClick={handleOptionClick} value="roadmap">
              Roadmap
            </option>
            <option onClick={handleOptionClick} value="satellite">
              Satellite
            </option>
            <option onClick={handleOptionClick} value="hybrid">
              Hybrid
            </option>
            <option onClick={handleOptionClick} value="terrain">
              Terrain
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Map;
