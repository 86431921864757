import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationsServices } from "../../services";
import cross_icon from "./cross_alp.png";
import "./Plot.css";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default function Plot({ map, label }) {
  const dispatch = useDispatch();
  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );
  const currentSelectedFieldId = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldId
  );
  //   const dispatch = useDispatch();
  const selectedFieldCoords = useSelector(
    (state) => state.FieldDetails.selectedFieldCoords
  );
  const selectedFieldPolygonGeoms = useSelector(
    (state) => state.FieldDetails.selectedFieldPolygonGeoms
  );

  //   For the map plotting
  const [markers, setMarkers] = useState([]);
  const [polygons, setPolygons] = useState([]);

  let currentInfoWindow = null;

  function openMarkerInfoWindow(marker, field_uid, location_no, title) {
    // Close the current info window if it exists
    if (currentInfoWindow) {
      currentInfoWindow.close();
    }

    // Create a new info window and set its content
    const infoWindow = new window.google.maps.InfoWindow({
      content: `<div><b>Location Details:</b> <p> <b>FIELD: </b> ${field_uid}</p><p><b>LOCATION_NO:</b> ${location_no}</p><p> <b>DATE : </b> ${title}</p></div>`,
    });

    // Open the new info window
    infoWindow.open(map, marker);

    // Set the current info window to the new one
    currentInfoWindow = infoWindow;
  }

  // useEffect(() => {
  //   if (map) {
  //     new MarkerClusterer({
  //       map,
  //       markers,
  //     });
  //   }
  // }, [markers]);

  // It is for the points side Effect
  useEffect(() => {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null); // remove the marker from the map
    }
    setMarkers([]); // clear the array
    const allMarkers = [];
    if (selectedFieldCoords !== null && window.google) {
      selectedFieldCoords.map((item) => {
        // console.log(item, "marker");
        const coordinate = {
          lat: item.geom.coordinates[0],
          lng: item.geom.coordinates[1],
        };

        const icon = {
          path: "M5.5 7h5v1h-5v5h-1v-5h-5v-1h5v-5h1v5z",
          fillColor: "#0077FF",
          fillOpacity: 1,
          scale: 1.5,
          strokeColor: "#0077FF",
          strokeWeight: 1,
        };

        const imageWidth = 32;
        const imageHeight = 32;

        const markerIcon = new window.google.maps.MarkerImage(
          cross_icon,
          new window.google.maps.Size(imageWidth, imageHeight),
          new window.google.maps.Point(0, 0),
          new window.google.maps.Point(16, 16),
          new window.google.maps.Size(imageWidth, imageHeight)
        );

        const marker = new window.google.maps.Marker({
          position: coordinate,
          icon: markerIcon,
          map: map,
          properties: {
            fieldUId: item.field_uid,
            id: item.id,
            type: "point",
            location_no: item.location_no,
            title: item.title,
          },
          label: null,
          draggable: item.editable,
          clickable: true,
        });

        marker.addListener("click", function () {
          if (item.editMode) {
            LocationsServices.getAllCoordinatesOfAfields(
              currentSelectedFieldUID
            )
              .then((res) => {
                const fieldCoords = res.data.locations;
                const fieldCoords_updated = fieldCoords.map((item) => {
                  if (item.id === marker.properties.id) {
                    dispatch.DisplaySettings.toggleLocationNumber(true);

                    dispatch.CreatePointandPolygon.setCurrentLocationNumber(
                      marker.properties.location_no
                    );

                    const oldLat = marker.getPosition().lat();
                    const oldLng = marker.getPosition().lng();

                    // Do something with the new coordinates
                    dispatch.CreatePointandPolygon.setEditedPoint({
                      newLat: oldLat,
                      newLng: oldLng,
                      id: marker.properties.id,
                      location_no: marker.properties.location_no,
                    });

                    return {
                      ...item,
                      editable: true,
                      deleteMode: false,
                      editMode: true,
                    };
                  } else {
                    return {
                      ...item,
                      editable: false,
                      deleteMode: false,
                      editMode: true,
                    };
                  }
                });
                if (fieldCoords_updated.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldCoords(
                    fieldCoords_updated
                  );
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          } else if (item.deleteMode) {
            dispatch.CreatePointandPolygon.setDeletedPoint(
              marker.properties.id
            );
            marker.setIcon({
              path: window.google.maps.SymbolPath.CIRCLE,
              fillColor: "#FF0000", // specify the fill color here
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 10,
            });
          } else {
            openMarkerInfoWindow(
              marker,
              item.field_uid,
              item.location_no,
              item.title
            );
          }
        });

        if (label) {
          marker.setLabel({
            text: item.location_no,
            color: "#00FFFF",
            className: "marker-position",
          });
        }

        // marker.addListener("mouseover", function () {
        //   // marker.setIcon();
        //   marker.setLabel({
        //     text: item.location_no,
        //     color: "#00FFFF",
        //     className: "marker-position",
        //   });
        // });

        // marker.addListener("mouseout", function () {
        //   console.log("hover on marker ");
        //   // openMarkerInfoWindow(
        //   //   marker,
        //   //   item.field_uid,
        //   //   item.location_no,
        //   //   item.title
        //   // );
        //   // marker.setIcon(markerIcon);
        //   marker.setLabel(null);
        // });

        // Add a zoom_changed event listener to the map
        // Add a click event listener to the map
        // Add a dragend event listener to the marker
        marker.addListener("dragend", function (event) {
          // Get the new coordinates of the marker
          var newLatLng = event.latLng;
          var newLat = newLatLng.lat();
          var newLng = newLatLng.lng();

          // Do something with the new coordinates
          dispatch.CreatePointandPolygon.setEditedPoint({
            newLat: newLat,
            newLng: newLng,
            id: marker.properties.id,
            location_no: marker.properties.location_no,
          });
        });

        allMarkers.push(marker); // add the marker to the array
      });

      setMarkers(allMarkers);
    }
  }, [map, selectedFieldCoords, label]);

  // It is for the polygon side Effect
  useEffect(() => {
    for (var i = 0; i < polygons.length; i++) {
      polygons[i].setMap(null); // remove the polygon from the map
    }
    setPolygons([]); // clear the array
    const allPolygons = [];
    if (selectedFieldPolygonGeoms !== null && window.google) {
      selectedFieldPolygonGeoms.map((item) => {
        // console.log(item, "polygon");
        const polygonCoords = item.geom.coordinates[0].map((point) => {
          return { lat: point[1], lng: point[0] };
        });
        const polygon = new window.google.maps.Polygon({
          paths: polygonCoords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          editable: item.editable,
          draggable: item.editable,
          clickable: item.editMode || item.deleteMode ? true : false,
          properties: { fieldId: item.fieldId, id: item.id },
        });

        polygon.addListener("click", () => {
          if (item.editMode) {
            LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
              .then((res) => {
                const polygonGeoms = res.data.map((item) => {
                  if (item.fieldId === polygon.properties.fieldId) {
                    return {
                      ...item,
                      deleteMode: false,
                      editable: true,
                      editMode: true,
                    };
                  } else {
                    return {
                      ...item,
                      deleteMode: false,
                      editable: false,
                      editMode: true,
                    };
                  }
                });
                if (polygonGeoms.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldPolygonGeoms(
                    polygonGeoms
                  );
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          } else if (item.deleteMode) {
            dispatch.CreatePointandPolygon.setDeletedPolygon(
              polygon.properties.id
            );
            polygon.setOptions({ fillColor: "#00FF00" });
          }
        });

        const paths = polygon.getPaths();
        paths.forEach(function (path, index) {
          window.google.maps.event.addListener(
            polygon,
            "mouseup",
            function (vertexIndex) {
              // get the updated geometry
              const updatedPaths = polygon.getPaths();
              // console.log(updatedPaths, "edited");
              const updatedCoords = updatedPaths
                .getAt(index)
                .getArray()
                .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

              dispatch.CreatePointandPolygon.setEditedPolygon({
                id: polygon.properties.id,
                geometry: updatedCoords,
              });
            }
          );
          window.google.maps.event.addListener(
            polygon,
            "dragend",
            function (vertexIndex) {
              // get the updated geometry
              const updatedPaths = polygon.getPaths();
              const updatedCoords = updatedPaths
                .getAt(index)
                .getArray()
                .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

              // log the updated geometry to the console
              dispatch.CreatePointandPolygon.setEditedPolygon({
                id: polygon.properties.id,
                geometry: updatedCoords,
              });
            }
          );
        });

        polygon.setMap(map);
        allPolygons.push(polygon); // add the polygon to the array
      });
      setPolygons(allPolygons);
    }
  }, [map, selectedFieldPolygonGeoms]);

  return null;
}
