const DisplaySettings = {
  state: {
    showMakeGrid: false,
    showFieldCard: true,
    showEditbuttons: false,
    showEditbuttonsEvent: false,
    showLatLongBox: false,
    showLocationNumber: false,
    showMapEventLoaderGrid: false,
    showMapEventLoaderDelete: false,
  },
  reducers: {
    toggleMakeGrid: (state) => {
      return {
        ...state,
        showMakeGrid: !state.showMakeGrid,
      };
    },
    toggleFieldCard: (state) => {
      return {
        ...state,
        showFieldCard: !state.showFieldCard,
      };
    },
    toggleEditButtons: (state, payload) => {
      return {
        ...state,
        showEditbuttons: payload,
      };
    },
    toggleEditButtonsEvent: (state, payload) => {
      return {
        ...state,
        showEditbuttonsEvent: payload,
      };
    },
    toggleLatLongBox: (state, payload) => {
      return {
        ...state,
        showLatLongBox: payload,
      };
    },
    toggleLocationNumber: (state, payload) => {
      return {
        ...state,
        showLocationNumber: payload,
      };
    },
    toggleMapEventLoaderGrid: (state, payload) => {
      return {
        ...state,
        showMapEventLoaderGrid: payload,
      };
    },
    toggleMapEventLoaderDelete: (state, payload) => {
      return {
        ...state,
        showMapEventLoaderDelete: payload,
      };
    },
    toggleEditStateAfterCancelOrSave: (state) => {
      return {
        ...state,
        showEditbuttons: true,
        showEditbuttonsEvent: false,
        showLatLongBox: false,
        showLocationNumber: false,
        showFieldCard: true,
        showMapEventLoaderGrid: false,
        showMapEventLoaderDelete: false,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default DisplaySettings;