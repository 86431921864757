import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Hooks";
import { Colors, setAuthToken, setupAxios } from "../Utils";
import Label from "./Label";

const pages = [
  "Home",
  "Timeline",
  "Tables",
  "Charts",
  "Maps",
  "Locations",
  "PPP",
  "Logout",
];
function TopBar() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const appSettingsState = useSelector((state) => state.AppSettings);
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("selected-field");
    localStorage.removeItem("selected-insect");
    localStorage.removeItem("mapper_filter");
    dispatch.AppSettings.Reset();
    dispatch.AppSettings.SetPage("Home");
    dispatch.Fields.Reset();
    dispatch.Insects.Reset();
    dispatch.PPP.Reset();
    setAuthToken("");
    setupAxios(axios);
    dispatch.Auth.Logout();
    navigate("/auth/login");
  };
  const [role, setRole] = useState("user");

  useEffect(() => {
    const objUser = JSON.parse(localStorage.getItem("user"));
    setRole(objUser.user_type);
  }, []);

  return (
    <div
      className=" mt-2 mr-2 mb-2 p-3 rounded-md"
      style={{ background: Colors.PRIMARY }}
    >
      {pages.map(
        (text, id) =>
          (text !== "Locations" || role === "superAdmin") && (
            <Label
              text={text}
              key={id}
              selected={text === appSettingsState.selectedPage ? true : false}
              onClick={() => {
                if (text === "Logout") {
                  logout();
                } else {
                  dispatch.AppSettings.SetPage(text);
                  navigate(text === "Home" ? "/" : "/" + text.toLowerCase());
                }
              }}
              size="text-2xl"
              padding="ml-5 mr-5"
            />
          )
      )}
    </div>
  );
}

export default TopBar;
