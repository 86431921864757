import isEmpty from "./isEmpty";
const ifExists = (list, value, return_value = true, field = "") => {
  let result = null;
  if (isEmpty(field)) result = list.filter((listItem) => listItem === value);
  else result = list.filter((listItem) => listItem[field] === value);

  if (return_value) return result;
  else {
    if (isEmpty(result)) return false;
    else return true;
  }
};

export { ifExists };
