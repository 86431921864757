import MapSection from "./MapSection/MapSection";
import SideBar from "./SideBar/SideBar";
import { Grid } from "@mui/material";
import { useState } from "react";
import Map from "./Map/Map";

const center = { lat: 39.9334, lng: 32.8597 };
const zoom = 12;

export default function Locations() {
  const [map, setMap] = useState();

  const onMap = (evMap) => {
    if (evMap) {
      setMap(evMap);
    }
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <SideBar map={map} />
      </Grid>
      <Grid item xs={9}>
        <Map center={center} zoom={zoom} onMap={onMap} />
        <MapSection map={map} />
      </Grid>
    </Grid>
  );
}
