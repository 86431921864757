import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import { useDispatch, useSelector } from "react-redux";
import { LocationsServices } from "../../services";

export default function EditAPoint({ map }) {
  const dispatch = useDispatch();
  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );

  const handleEditPointButton = () => {
    LocationsServices.getAllCoordinatesOfAfields(currentSelectedFieldUID)
      .then((res) => {
        const fieldCoords = res.data.locations;
        const fieldCoords_updated = fieldCoords.map((item) => {
          return {
            ...item,
            editable: false,
            deleteMode: false,
            editMode: true,
          };
        });
        if (fieldCoords_updated.length > 0) {
          dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
    dispatch.DisplaySettings.toggleEditButtons(false);
  };

  return (
    <Tooltip title="Edit a Point">
      <IconButton
        onClick={handleEditPointButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Edit a Point"
      >
        <EditLocationAltIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
