import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FieldCard, Spinner } from "../../Components";
import { useLoading } from "../../Hooks";
import useStorage from "../../Hooks/useStorage";
import { FieldServices, InsectServices } from "../../services";
import { CheckExistance, Constants, isEmpty } from "../../Utils";
function Home() {
  let [isLoading, ChangeState] = useLoading(false);
  const fieldsState = useSelector((state) => state.Fields);
  const appSettingsDateState = useSelector((state) => state.AppSettings.dates);
  const insectsState = useSelector((state) => state.Insects);
  const selectedInsect = insectsState.selected;
  const dispatch = useDispatch();
  const selectedField = fieldsState.selected;
  const dispatchFields = fieldsState.fields;
  let [fields, setFields] = useState([]);
  const [storageInsect, setStorageInsect] = useStorage(
    "",
    Constants.SELECTED_INSECT_KEY
  );
  const [storageField, setStorageField] = useStorage(
    "",
    Constants.SELECTED_FIELD_ID
  );

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const generateFilterParams = () => {
    var filter = "";
    Object.keys(fieldsState.filter).forEach((key) => {
      //Arranging it in case of single selector company
      if (Array.isArray(fieldsState.filter[key])) {
        fieldsState.filter[key]?.map((item, index) => {
          filter += `${key}[${index}]=${item.value}&`;
        });
        return;
      }
      filter += `${key}[0]=${fieldsState.filter[key].value}&`;
    });
    return filter;
  };

  const getMyFields = () => {
    ChangeState();
    let filter = generateFilterParams();
    FieldServices.getMyFields(filter).then((res) => {
      let resFields = res.data.data;
      const uniqueCompanies = resFields
        .map((field) => field.company_id)
        .filter((value, index, self) => self.indexOf(value) === index);
      let finalFields = {};
      const stateFields = resFields.map((field, index) => {
        //data for the company based on home page
        if (finalFields[field.company.title]) {
          finalFields[field.company.title].push(field);
        } else {
          finalFields[field.company.title] = [field];
        }
        //returning data for the state fields
        return field;
      });
      setFields(finalFields);
      dispatch.Fields.SetFields(stateFields);
      let districtColors = [];
      let colors = [
        "#008000",
        "#FF0000",
        "#008080",
        "#0000FF",
        "#800080",
        "#40E0D0",
        "#6495ED",
        "#808000",
      ];
      for (var i = 0; i < uniqueCompanies.length; i++) {
        districtColors = [
          ...districtColors,
          { company_id: uniqueCompanies[i], color: colors[((i + 1) % 8) - 1] },
        ];
      }
      dispatch.Fields.SetColors(districtColors);
      ChangeState();
    });
  };

  useEffect(() => {
    if (fieldsState.applyFilter) {
      getMyFields();
    }
  }, [fieldsState.applyFilter]);

  useEffect(() => {
    getMyFields();
  }, []);

  useEffect(() => {
    let field = null;
    if (dispatchFields.length > 0) {
      field = dispatchFields[0];
      if (!isEmpty(storageField)) {
        const tempField = dispatchFields.filter(
          (field) => field.id === storageField
        );
        if (tempField.length > 0) field = tempField[0];
      }
      selectField(field);
    }
  }, [dispatchFields]);

  const selectField = (field) => {
    setStorageField(field?.id);
    dispatch.Fields.Select({
      id: field?.id,
      name: field?.title,
      uId: field?.uid,
      status: "",
    });
    if(!insectsState.selectedDisease){

      if (!!field) {
        getInsectsOfAField(field);
      } else {
        dispatch.Insects.SetInsects({ selected: null, data: null });
      }
    }
  };

  const getInsectsOfAField = (field) => {
    InsectServices.getInsectsOfAField(field.company_id, field.ui_field_type_id)
      .then((res) => {
        let insects = res.data.insects.map((insect) => insect.name);
        setInsectsOfAField(insects);
        getLatestDatesOfAField(field.uid);
      })
      .catch((err) => {
        setInsectsOfAField([]);
        toast.error("Error getting insects!");
      });
  };

  const setInsectsOfAField = (insects) => {
    let dataToDispatch = { selected: null, data: null };
    let insectCode = insectsState.selected;
    if (insects.length > 0 && !insectsState.selectedDisease && !insectCode) {
      insectCode = insects[0];
      if (!isEmpty(storageInsect)) {
        if (CheckExistance.ifExists(insects, storageInsect, false)) {
          insectCode = storageInsect;
        }
      }
    }
    dataToDispatch = {
      selected: insectCode,
      data: insects,
    };
    setStorageInsect(insectCode);
    dispatch.Insects.SetInsects(dataToDispatch);
  };

  const getLatestDatesOfAField = (fieldIUd) => {
    FieldServices.getLatestDatesOfAField(fieldIUd)
      .then((res) => {
        let startDate = res.data.start_date;
        let endDate = res.data.end_date;
        dispatch.AppSettings.SetNextDates({
          startDate,
          endDate,
        });
      })
      .catch((err) => {
        toast.error("Error getting dates!");
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="flex min-h-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {Object.entries(fields).map(([key, fields]) => (
            <div className="field-group">
              <h3>{key}</h3>
              <div className="flex flex-wrap">
                {fields.map((field, index) => {
                  return (
                    <div key={index}>
                      <FieldCard
                        field={field}
                        {...field}
                        key={`field-${index}`}
                        selected={selectedField === field.id ? true : false}
                        onClick={selectField}
                      />
                      {field.breakline && (
                        <div
                          style={{
                            flexBasis: "100%",
                            height: 0,
                          }}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Home;
