import React, { useEffect, useState } from "react";
import { TableComponents } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { FieldServices, PPPServices } from "../../services";
import moment from "moment";
import _ from "lodash";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import notesImage from "../../Assets/Images/notes.png";

const PPP = () => {
  const columns = [
    {
      width: "14%",
      name: "PPP name.",
      columnName: "pppName",
      type: "label",
    },
    {
      width: "14%",
      name: "Application date",
      columnName: "applicationDate",
      type: "label",
    },
    {
      width: "14%",
      name: "PPP amount",
      columnName: "pppAmount",
      type: "label",
    },
    {
      width: "14%",
      name: "Water amount",
      columnName: "waterAmount",
      type: "label",
    },
    {
      width: "14%",
      name: "Target Crop",
      columnName: "targetCrop",
      type: "label",
    },
    {
      width: "10%",
      name: "Sectors",
      columnName: "sectors",
      type: "label",
    },
    {
      width: "10%",
      name: "Tunnels",
      columnName: "tunnels",
      type: "label",
    },
    {
      width: "5%",
      name: "",
      columnName: "action",
      type: "label",
    },
  ];

  let [selectSingle, setSingleSelect] = useState(["none"]);
  let [sortSingle, setSingleSort] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const [showPPP, setShowPPP] = useState(false);

  const seasonDate = async () => {
    if (fieldsState.uId) {
      const result = await FieldServices.getSeasonDate(fieldsState.uId);

      var startDate = result.data.data
        ? moment(result.data.data.start_date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      var endDate = moment().format("YYYY-MM-DD");
      dispatch.AppSettings.SetNextDates({
        startDate,
        endDate,
      });
    }
  };

  let PPPState = useSelector((state) => state.PPP);
  const fieldsState = useSelector((state) => state.Fields);
  let fieldUId = fieldsState.uId;

  useEffect(() => {
    if (!PPPState.chartDate) {
      seasonDate();
    }
    dispatch.PPP.SetChartDate(false);
  }, []);

  const dispatch = useDispatch();
  let treatments = useSelector((state) => state.PPP.treatments);
  let selectedTreatment = useSelector((state) => state.PPP.selectedTreatment);
  const appSettingsState = useSelector((state) => state.AppSettings);

  const [applicationData, setApplicationData] = useState({
    applications: [],
    latest_date: null,
    total_product: 0,
    total_water: 0,
  });

  useEffect(() => {
    if (treatments.length > 0) {
      if (selectedTreatment) {
        getTreatmentApplication(selectedTreatment.id);
      } else {
        getTreatmentApplication("all");
      }
    }else{
      setApplicationData({
        applications: [],
        latest_date: null,
        total_product: 0,
        total_water: 0,
      });
      dispatch.PPP.SetSelectedTreatment(null);
    }
  }, [treatments, selectedTreatment]);

  const getTreatmentApplication = (treatmentId) => {
    console.log("application");
    let nextStartDate = appSettingsState.dates.nextDate.startDate;
    let nextEndDate = appSettingsState.dates.nextDate.endDate;
    if (nextStartDate && nextEndDate) {
      PPPServices.getFieldApplications(
        fieldUId,
        nextStartDate,
        nextEndDate,
        treatmentId
      )
        .then((res) => {
          const data = res.data.data;

          setApplicationData({
            applications:
              data?.applications?.map((item) => {
                return {
                  pppName: item?.treatment?.name || "",
                  applicationDate: item?.application
                    ? moment.utc(item?.application?.createdAt).format("DD.MM.YYYY")
                    : "",
                  pppAmount: item?.product_used || "",
                  waterAmount: item?.application?.water_used || "0",
                  targetCrop: item?.treatment?.target_crop || "",
                  sectors: item?.application?.sector || "",
                  tunnels: item?.application?.tunnel || "",
                  application: item?.application,
                };
              }) || [],
            latest_date: data?.latest_date || null,
            total_product: data.total_product || 0,
            total_water: data.total_water || 0,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleClickSingle = (field, type) => {
    const data = applicationData?.applications;
    let sortedData = [];
    sortedData = _.orderBy(data, [field], [sortSingle ? "asc" : "desc"]);
    setSingleSort((state) => !state);
    setSingleSelect([field]);
    setApplicationData({ ...applicationData, applications: [...sortedData] });
  };

  return (
    <>
      <div className="ppp-header">
        <div className="title">
          {selectedTreatment?.name || ""}{" "}
          {selectedTreatment && (
            <span
              onClick={() => {
                setShowPPP(true);
              }}
            >
              - Detail
            </span>
          )}
        </div>
        <div>
          Last use date:{" "}
          {applicationData?.latest_date
            ? moment(applicationData?.latest_date).format("DD.MM.YYYY")
            : "N/A"}
        </div>
        <div>PHI: {selectedTreatment?.pre_harvest_interval || "N/A"}</div>
        <div>Total amount water: {applicationData?.total_water || 0}L</div>
        <div>Total amount PPP: {applicationData?.total_product || 0}</div>
      </div>
      <div className="ppp-content-block">
        <div className="ppp-table">
          <div>
            <table
              className="table-auto border-separate border-spacing-1"
              style={{ width: "100%" }}
            >
              <tr className="text-center text-sm bg-amber-400 text-white">
                {columns.map((column) => (
                  <TableComponents.ColumnHeader
                    width={column.width}
                    name={column.name}
                    sort={sortSingle}
                    select={selectSingle}
                    columnName={column.columnName}
                    type={column.type}
                    onClick={(name, type) => {
                      handleClickSingle(name, type);
                    }}
                  />
                ))}
              </tr>
            </table>
          </div>
          <div>
            <table
              className="table-auto border-separate border-spacing-1"
              style={{ width: "100%" }}
            >
              <tr className="text-center text-sm bg-amber-400 text-white hidden">
                {columns.map((column) => (
                  <TableComponents.ColumnHeader
                    width={column.width}
                    name={column.name}
                    sort={sortSingle}
                    select={selectSingle}
                    columnName={column.columnName}
                    type={column.type}
                    onClick={(name, type) => {
                      handleClickSingle(name, type);
                    }}
                  />
                ))}
              </tr>
              {applicationData?.applications?.map((item) => (
                <tr
                  className="text-right text-md border-white"
                  style={{ backgroundColor: "#f3f4f6" }}
                >
                  <td style={{ width: "14%" }}>{item?.pppName}</td>
                  <td style={{ width: "14%" }}>{item?.applicationDate}</td>
                  <td style={{ width: "14%" }}>{item?.pppAmount}</td>
                  <td style={{ width: "14%" }}>{item?.waterAmount}L</td>
                  <td style={{ width: "14%" }}>{item?.targetCrop}</td>
                  <td style={{ width: "10%" }}>{item?.sectors}</td>
                  <td style={{ width: "10%" }}>{item?.tunnels}</td>
                  <td style={{ width: "5%", textAlign: "center" }}>
                    <img
                      src={notesImage}
                      alt="notes"
                      className="note-image"
                      onClick={() => {
                        setSelectedApplication(item?.application);
                        setShow(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="application-list">
          <div onClick={() => dispatch.PPP.SetSelectedTreatment(null)}>All</div>
          {treatments.map((item) => {
            return (
              <div onClick={() => dispatch.PPP.SetSelectedTreatment(item)}>
                {item.name}
              </div>
            );
          })}
        </div>
      </div>

      <Dialog
        onClose={() => {
          setShow(false);
        }}
        open={show}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Application Notes</DialogTitle>
        <DialogContent>
          <div class="application-notes">
            {selectedApplication?.notes || ""}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowPPP(false);
        }}
        open={showPPP}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>PPP Information</DialogTitle>
        <DialogContent>
          <div class="list-group">
            <h5>Name: </h5>
            <p>{selectedTreatment?.name || ""}</p>
          </div>
          <div class="list-group">
            <h5>PPP Type: </h5>
            <p>{selectedTreatment?.treatment_type?.name || ""}</p>
          </div>
          <div class="list-group">
            <h5>Method: </h5>
            <p>{selectedTreatment?.method || ""}</p>
          </div>
          <div class="list-group">
            <h5>Seasonal: </h5>
            <p>{selectedTreatment?.seasonal || ""}</p>
          </div>
          <div class="list-group">
            <h5>PHI: </h5>
            <p>{selectedTreatment?.pre_harvest_interval || ""}</p>
          </div>
          <div class="list-group">
            <h5>Mode of action: </h5>
            <p>{selectedTreatment?.mode_of_action || ""}</p>
          </div>
          <div class="list-group">
            <h5>Target Crop: </h5>
            <p>{selectedTreatment?.target_crop || ""}</p>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PPP;
