import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { LocationsServices } from "../../services";

export default function DeleteAPoint() {
  const dispatch = useDispatch();
  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );
  const handleDeletePointButton = () => {
    LocationsServices.getAllCoordinatesOfAfields(currentSelectedFieldUID)
      .then((res) => {
        const fieldCoords = res.data.locations;
        const fieldCoords_updated = fieldCoords.map((item) => {
          return {
            ...item,
            editable: false,
            deleteMode: true,
            editMode: false,
          };
        });
        if (fieldCoords_updated.length > 0) {
          dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
    dispatch.DisplaySettings.toggleEditButtons(false);
  };
  return (
    <Tooltip title="Delete a Point">
      <IconButton
        onClick={handleDeletePointButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Delete a Point"
      >
        <DeleteIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
