import moment from "moment";

export const fetchByDotOperator = (object, value) => {
  return value.split(".").reduce((acc, curr) => acc[curr], object);
};
export const GetNestedObjectString = (array) => {
  let arrString = "";
  array.map((a, i) => {
    arrString = arrString + a + "[0].";
  });
  return arrString;
};
export const GetNestedArrayString = (array) => {
  let arrString = "";
  array.map((a, i) => {
    i === array.length - 1
      ? (arrString = arrString + a + "[0].")
      : (arrString = arrString + a + ".");
  });
  return arrString;
};

export const CalculateTime = (start_date, end_date) => {
  const startTime = moment(start_date);
  const endTime = moment(end_date);

  var duration = moment.duration(endTime.diff(startTime));

  var total_hours = parseInt(duration.asHours());
  if (total_hours < 10) {
    total_hours = "0" + total_hours;
  }
  var total_minutes = parseInt(duration.asMinutes()) % 60;
  if (total_minutes < 10) {
    total_minutes = "0" + total_minutes;
  }
  return total_hours + ":" + total_minutes;
};
