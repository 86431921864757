import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MapIcon from "@mui/icons-material/Map";
import { LocationsServices } from "../../services";
import * as turf from "@turf/turf";

export default function FieldCard({ index, _, map }) {
  const dispatch = useDispatch();
  const [selectedFieldCoords, SetSelectedFieldCoords] = useState(null);
  const [selectedFieldPolygonGeoms, SetSelectedFieldPolygonGeoms] =
    useState(null);
  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );

  const selectedFieldCoords_ = useSelector(
    (state) => state.FieldDetails.selectedFieldCoords
  );
  const selectedFieldPolygonGeoms_ = useSelector(
    (state) => state.FieldDetails.selectedFieldCoords
  );

  useEffect(() => {
    if (selectedFieldCoords || selectedFieldPolygonGeoms) {
      if (selectedFieldCoords) {
        var minLat = 999,
          minLng = 999,
          maxLat = -999,
          maxLng = -999;
        for (var i = 0; i < selectedFieldCoords.length; i++) {
          var lat = selectedFieldCoords[i].geom.coordinates[0];
          var lng = selectedFieldCoords[i].geom.coordinates[1];
          if (lat < minLat) minLat = lat;
          if (lng < minLng) minLng = lng;
          if (lat > maxLat) maxLat = lat;
          if (lng > maxLng) maxLng = lng;
        } // Create the bounding box
        var bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(minLat, minLng), // Southwest corner
          new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
        );
        map.fitBounds(bounds);
      } else {
        if (selectedFieldPolygonGeoms) {
          const turfPolygons = [];

          selectedFieldPolygonGeoms.forEach((polygon) => {
            turfPolygons.push(turf.polygon(polygon.geom.coordinates));
          });
          const bbox = turf.bbox(turf.featureCollection(turfPolygons));
          const bounds_ = new window.google.maps.LatLngBounds(
            { lat: bbox[1], lng: bbox[0] },
            { lat: bbox[3], lng: bbox[2] }
          );
          map.fitBounds(bounds_);
        }
      }
    }
  }, [selectedFieldCoords, selectedFieldPolygonGeoms, map]);

  const handleShowMakeGrid = (event) => {
    dispatch.DisplaySettings.toggleMakeGrid();
    dispatch.DisplaySettings.toggleFieldCard();
    dispatch.DisplaySettings.toggleEditButtons(false);
    dispatch.DisplaySettings.toggleEditButtonsEvent(false);
    dispatch.DisplaySettings.toggleLatLongBox(false);
    dispatch.DisplaySettings.toggleLocationNumber(false);
  };

  const handleClickOnField = (event) => {
    const myValue = event.currentTarget.getAttribute("data-my-value");
    const splitted_data = myValue.split(",");
    dispatch.FieldDetails.resetFieldDetails();
    dispatch.FieldDetails.setcurrentSelectedFieldUID(splitted_data[0]);
    dispatch.FieldDetails.setcurrentSelectedFieldId(splitted_data[1]);
    dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
    LocationsServices.getAllCoordinatesOfAfields(splitted_data[0])
      .then((res) => {
        const fieldCoords = res.data.locations;
        const fieldCoords_updated = fieldCoords.map((item) => {
          return {
            ...item,
            deleteMode: false,
            editable: false,
            editMode: false,
          };
        });
        if (fieldCoords_updated.length > 0) {
          dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
          SetSelectedFieldCoords(fieldCoords_updated);
        } else {
          dispatch.FieldDetails.setSelectedFieldCoords(null);
        }
        dispatch.DisplaySettings.toggleEditButtons(true);
      })
      .catch((err) => {
        console.log(err, "err");
        dispatch.DisplaySettings.toggleEditButtons(true);
      });
    LocationsServices.getAllPolygonsOfAfields(splitted_data[1])
      .then((res) => {
        const polygonGeoms = res.data.map((item) => {
          return {
            ...item,
            deleteMode: false,
            editable: false,
            editMode: false,
          };
        });
        if (polygonGeoms.length > 0) {
          dispatch.FieldDetails.setSelectedFieldPolygonGeoms(polygonGeoms);
          SetSelectedFieldPolygonGeoms(polygonGeoms);
        } else {
          dispatch.FieldDetails.setSelectedFieldPolygonGeoms(null);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handleClickedOnZoomToExtent = () => {
    console.log("clicked");
    if (selectedFieldCoords_ || selectedFieldPolygonGeoms_) {
      if (selectedFieldCoords_) {
        var minLat = 999,
          minLng = 999,
          maxLat = -999,
          maxLng = -999;
        for (var i = 0; i < selectedFieldCoords_.length; i++) {
          var lat = selectedFieldCoords_[i].geom.coordinates[0];
          var lng = selectedFieldCoords_[i].geom.coordinates[1];
          if (lat < minLat) minLat = lat;
          if (lng < minLng) minLng = lng;
          if (lat > maxLat) maxLat = lat;
          if (lng > maxLng) maxLng = lng;
        } // Create the bounding box
        var bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(minLat, minLng), // Southwest corner
          new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
        );
        map.fitBounds(bounds);
      } else {
        if (selectedFieldPolygonGeoms_) {
          const turfPolygons = [];

          selectedFieldPolygonGeoms_.forEach((polygon) => {
            turfPolygons.push(turf.polygon(polygon.geom.coordinates));
          });
          const bbox = turf.bbox(turf.featureCollection(turfPolygons));
          const bounds_ = new window.google.maps.LatLngBounds(
            { lat: bbox[1], lng: bbox[0] },
            { lat: bbox[3], lng: bbox[2] }
          );
          map.fitBounds(bounds_);
        }
      }
    }
  };

  return (
    <Card
      key={index}
      style={{
        marginTop: "5px",
        padding: "5px",
        marginRight: "5px",
        marginLeft: "5px",
      }}
    >
      <CardContent sx={{ backgroundColor: "#e3ede9" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {_.title} {index + 1}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" component="p">
          UID:{_.uid}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          {currentSelectedFieldUID === _.uid ? (
            <Box sx={{ display: "inline-flex", gap: "8px" }}>
              <Button
                sx={{ backgroundColor: "palegreen" }}
                disabled={true}
                data-my-value={`[_.uid, _.id]`}
                variant="contained"
                color="secondary"
              >
                Added
              </Button>
              <Button
                sx={{ backgroundColor: "red" }}
                data-my-value={[_.uid, _.id]}
                variant="contained"
                color="primary"
                onClick={handleShowMakeGrid}
              >
                Preview Grid
              </Button>
              <Tooltip sx={{ bgcolor: "#f00" }} title="ZoomToExtent">
                <IconButton
                  sx={{ "&:hover": { bgcolor: "#90EE90" } }}
                  aria-label="delete"
                  onClick={handleClickedOnZoomToExtent}
                >
                  <MapIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Button
              data-my-value={[_.uid, _.id]}
              variant="contained"
              color="primary"
              onClick={handleClickOnField}
            >
              View on Map
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
