import classNames from "classnames";
import React from "react";
import { Colors } from "../Utils";

function Label({
  onClick = null,
  size = null,
  text = "",
  selected = false,
  padding = "",
  color = Colors.WHITE,
  className = "",
  error,
}) {
  return (
    <label
      onClick={onClick}
      className={classNames(
        "font-black",
        { [size]: size },
        { [padding]: padding },
        "cursor-pointer",
        className
      )}
      style={{
        color: error ? Colors.RED : selected ? Colors.PRIMARY_DARK : color,
      }}
    >
      {text}
    </label>
  );
}

export default Label;
