import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { LocationsServices } from "../../services";
import { useDispatch } from "react-redux";

export default function CustomAutoComplete() {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState();

  useEffect(() => {
    LocationsServices.getAllCompanies()
      .then((res) => {
        const companiesAll = res.data.companies.rows;
        setCompanies(companiesAll);
      })
      .catch((err) => {
        console.log(err, "error failure");
      });
  }, []);

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 10,
        backgroundColor: "white",
      }}
    >
      <Autocomplete
        options={companies ? companies : []}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, value) => {
          if (value.id !== null) {
            dispatch.CompanyFields.setFields([]);
            dispatch.Loaders.toggleLoadingFields();
            LocationsServices.getfieldsByCompany(value.id)
              .then((res) => {
                dispatch.Loaders.toggleLoadingFields();
                dispatch.CompanyFields.setFields(res.data.fields);
              })
              .catch((err) => {
                console.log(err, "error failure");
              });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Search Company"
            margin="normal"
          />
        )}
      />
    </div>
  );
}
