import React from "react";
import { TextField } from "@mui/material";

function FloatInput(props) {
  const { id, label, value, onChange, readOnly, error, helperText } = props;

  // Define a function to validate the input as a float
  const isValidFloat = (value) => {
    return !isNaN(parseFloat(value));
  };

  // Define a function to handle the input change
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (isValidFloat(newValue) || newValue === "") {
      onChange(event);
    }
  };

  return (
    <TextField
      required={true}
      id={id}
      label={label}
      value={value}
      onChange={handleInputChange}
      InputProps={{ readOnly }}
      error={error}
      helperText={helperText}
      InputLabelProps={{
        shrink: true,
        position: "above",
        color: "primary",
      }}
    />
  );
}

export default FloatInput;
