const FieldDetails = {
  state: {
    currentSelectedFieldUID: null,
    currentSelectedFieldId: null,
    selectedFieldCoords: null,
    selectedFieldPolygonGeoms: null,
  },
  reducers: {
    // To add UUID of a field selected
    setcurrentSelectedFieldUID: (state, payload) => {
      return {
        ...state,
        currentSelectedFieldUID: payload,
      };
    },
    // To add id of a field selected
    setcurrentSelectedFieldId: (state, payload) => {
      return {
        ...state,
        currentSelectedFieldId: payload,
      };
    },
    // To change the state  selected Fields coords in a particular field , one is to add whole new and other is to add only new one
    setSelectedFieldCoords: (state, payload) => {
      return {
        ...state,
        selectedFieldCoords: payload,
      };
    },
    // To change the state selected Polygon Geoms in a particular field
    // 1) To add a whole new array of fields
    setSelectedFieldPolygonGeoms: (state, payload) => {
      return {
        ...state,
        selectedFieldPolygonGeoms: payload,
      };
    },
    resetFieldDetails: (state) => {
      return {
        ...state,
        selectedFieldCoords: null,
        selectedFieldPolygonGeoms: null,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default FieldDetails;
