import { isEmpty } from "ol/extent";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Constants, Helpers } from "../../Utils";
import { Spinner } from "../../Components";
import useStorage from "../../Hooks/useStorage";
import { dialogActionsClasses, useIsFocusVisible } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Charts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let Fields = useSelector((state) => state.Fields);
  let PPP = useSelector((state) => state.PPP);
  let singleData = Fields.locationsData.single;
  let dualData = Fields.locationsData.dual;
  let singleChartData = Fields.chartData.single;
  let dualChartData = Fields.chartData.dual;
  let singleSelectedData = useSelector(
    (state) => state.Fields.selectedData.single
  );
  let dualSelectedData = useSelector((state) => state.Fields.selectedData.dual);
  let appSettings = useSelector((state) => state.AppSettings);
  let action = appSettings.action;
  let selectedChartMode = appSettings.selectedChartMode;
  let selectedMode = appSettings.selectedMode;
  let [isLoading, setIsLoading] = useState(false);
  const insectState = useSelector((state) => state.Insects);
  let [buttonClicked, setButtonClicked] = useState(false);

  let [dates, setDates] = useState(Helpers.getDatesbyMode(selectedChartMode));

  const [nextBarOptions, setNextBarOptions] = useState({
    chart: {
      background: "#fafafa",
      height: 350,
      type: "bar",
      events: {
        click: function (event, chartContext, config) {
          dispatch.Fields.AddSelectedData({
            type: "single",
            data: {
              id: singleData[config.dataPointIndex].location_no,
              index: config.dataPointIndex,
            },
          });
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: [],
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  });

  const [nextBarSeries, setNextBarSeries] = useState([
    {
      name: "Servings",
      data: [],
    },
  ]);

  const [previousBarOptions, setPreviousBarOptions] = useState({
    chart: {
      background: "#fafafa",
      height: 350,
      type: "bar",
      events: {
        click: function (event, chartContext, config) {
          dispatch.Fields.AddSelectedData({
            type: "dual",
            data: {
              id: dualData[config.dataPointIndex].location_no,
              index: config.dataPointIndex,
            },
          });
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: [],
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  });

  const [previousBarSeries, setPreviousBarSeries] = useState([
    {
      name: "Servings",
      data: [],
    },
  ]);

  const [prevBrushChartOptions, setPrevBrushChartOptions] = useState({
    chart: {
      background: "#fafafa",
      id: "chart4",
      type: "area",
      height: 230,
      foreColor: "#ccc",
      toolbar: {
        autoSelected: "zoom",
        show: true,
      },
    },
    colors: ["#ff8c00"],
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: "#555",
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.4,
        opacityTo: 0,
      },
    },
    markers: {
      size: 5,
      colors: ["#fcfcfc"],
      strokeColor: "#ff8c00",
      strokeWidth: 3,
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      theme: "light",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
    },
  });

  const [prevBrushChartLineOptions, setPrevBrushChartLineOptions] = useState({
    chart: {
      background: "#fafafa",
      id: "chart3",
      height: 130,
      type: "bar",
      foreColor: "#ccc",
      brush: {
        target: "chart4",
        enabled: true,
      },
      selection: {
        enabled: true,
        fill: {
          color: "#CCC",
          opacity: 0.5,
        },
        xaxis: {
          min: new Date("19 Jun 2017").getTime(),
          max: new Date("14 Aug 2017").getTime(),
        },
      },
    },
    colors: ["#406abf"],
    stroke: {
      width: 2,
    },
    grid: {
      borderColor: "#444",
      row: {
        colors: ["#f2f2f2"],
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 2,
    },
  });

  const [prevBrushChartSeries, setPrevBrushChartSeries] = useState([
    {
      name: `${insectState.selected} ${action !== "sum" ? "avg" : "sum"}`,
      data: [],
    },
  ]);

  const [prevBrushChartLineSeries, setPrevBrushChartLineSeries] = useState([
    {
      name: `${insectState.selected} ${action !== "sum" ? "avg" : "sum"}`,
      data: [],
    },
  ]);

  const [MAChartLineOptions, setMAChartLineOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      type: "float",
    },
  });

  const [MAChartLineSeries, setMABrushChartLineSeries] = useState([
    {
      name: `MA`,
      data: [],
    },
  ]);

  useEffect(() => {
    let singleMax,
      dualMax,
      max = 0;
    let maxObj = {};
    if (selectedMode !== "single" && action !== "changeRate") {
      singleMax = Math.max.apply(
        null,
        singleData.map(function (o) {
          return o[action];
        })
      );
      dualMax = Math.max.apply(
        null,
        dualData.map(function (o) {
          return o[action];
        })
      );

      max = singleMax > dualMax ? singleMax : dualMax;
      maxObj = { max: max };
    }
    if (!isEmpty(singleData)) {
      setNextBarOptions((state) => {
        return {
          ...state,
          chart: {
            height: 350,
            type: "bar",
            events: {
              click: function (event, chartContext, config) {
                dispatch.Fields.AddSelectedData({
                  type: "single",
                  data: {
                    id: singleData[config.dataPointIndex].location_no,
                    index: config.dataPointIndex,
                  },
                });
              },
            },
          },
          xaxis: {
            ...state.xaxis,
            labels: {
              rotate: -45,
            },
            categories: singleData.map((d) => d.location_no + ""),
            tickPlacement: "on",
          },
          yaxis: {
            ...maxObj,
            title: {
              text: action === "changeRate" ? "Chnage Rate %" : action,
            },
          },
          colors: [
            function ({ dataPointIndex }) {
              if (singleSelectedData.some((a) => a.index === dataPointIndex)) {
                return "#FF0000";
              } else {
                return "#02DFDE";
              }
            },
          ],
        };
      });
      setNextBarSeries([
        {
          name: `Location ${action}`,
          data: singleData.map((d) => parseFloat(d[action])),
        },
      ]);
    }
    if (selectedMode !== "single") {
      if (dualData.length > 0) {
        setPreviousBarOptions((state) => {
          return {
            ...state,
            chart: {
              height: 350,
              type: "bar",
              events: {
                click: function (event, chartContext, config) {
                  dispatch.Fields.AddSelectedData({
                    type: "dual",
                    data: {
                      id: dualData[config.dataPointIndex].location_no,
                      index: config.dataPointIndex,
                    },
                  });
                },
              },
            },
            xaxis: {
              ...state.xaxis,
              labels: {
                rotate: -45,
              },
              categories: dualData.map((d) => d.location_no + ""),
              tickPlacement: "on",
            },
            yaxis: {
              ...maxObj,
              title: {
                text:
                  selectedMode === "single"
                    ? action
                    : action === "changeRate"
                    ? "sum"
                    : action,
              },
            },
            colors: [
              function ({ dataPointIndex }) {
                if (dualSelectedData.some((a) => a.index === dataPointIndex)) {
                  return "#FF0000";
                } else {
                  return "#02DFDE";
                }
              },
            ],
          };
        });
        setPreviousBarSeries([
          {
            name: `Location ${
              selectedMode === "single"
                ? action
                : action === "changeRate"
                ? "sum"
                : action
            }`,
            data: dualData.map((d) =>
              parseFloat(
                selectedMode === "single"
                  ? d[action]
                  : action === "changeRate"
                  ? d.sum
                  : d[action]
              )
            ),
          },
        ]);
      }
    }
  }, [
    action,
    singleData,
    dualData,
    selectedMode,
    dualSelectedData,
    singleSelectedData,
  ]);
  useEffect(() => {
    let movingAverageSingle2 = [];
    let movingAverageSingle4 = [];
    let movingAverageSingle6 = [];
    if (dualChartData.length > 0) {
      movingAverageSingle2 = EMACalc(
        dualChartData.map((a) => a[action]),
        dualChartData.map((a) => new Date(a.endDate).getTime()),
        2
      );
      console.log("movingAverageSingle2", movingAverageSingle2);
      movingAverageSingle4 = EMACalc(
        dualChartData.map((a) => a[action]),
        dualChartData.map((a) => new Date(a.endDate).getTime()),
        3
      );
      movingAverageSingle6 = EMACalc(
        dualChartData.map((a) => a[action]),
        dualChartData.map((a) => new Date(a.endDate).getTime()),
        5
      );
    }

    setPrevBrushChartOptions((state) => {
      return {
        ...state,
        title: {
          text: `${Fields?.fieldDataName ? `${Fields?.fieldDataName},` : ""} ${
            insectState.selected
          } (
                ${dates.nextStartDate} - ${dates.nextEndDate})`,
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        annotations: {
          xaxis:
            Object.values(PPP?.chartData).map((value, index) => {
              const color =
                value[0]?.treatment?.treatment_type?.color || "#00E396";
              return {
                x: new Date(value[0].createdAt).getTime(),
                borderColor: color,
                strokeDashArray: 0,
                label: {
                  borderColor: color,
                  style: {
                    color: "#fff",
                    background: color,
                    fontSize: "12px",
                  },

                  click: () => setPPPDate(value[0].createdAt),
                  text: [
                    ...new Set(
                      value.map((application) => application?.treatment?.name)
                    ),
                  ]
                    .map((name) => name?.slice(0, 8) || "")
                    .join(", "),
                },
                offsetY: -20,
              };
            }) || [],
        },
      };
    });

    setPrevBrushChartLineOptions((state) => {
      return {
        ...state,
        chart: {
          ...state.chart,
          selection: {
            enabled: true,
            xaxis: {
              min: new Date(
                dualChartData[
                  Math.ceil(dualChartData.length / 1.2) - 1
                ]?.endDate
              ).getTime(),
              max: new Date(
                dualChartData[dualChartData.length - 1]?.endDate
              ).getTime(),
            },
          },
        },
        yaxis: {
          title: {
            text: action !== "sum" ? "avg" : "sum",
          },
        },
      };
    });

    setPrevBrushChartSeries([
      {
        name: action !== "sum" ? "avg" : "sum",
        data: dualChartData.map((a) => [
          new Date(a.endDate).getTime(),
          action !== "sum" ? a.avg : a.sum,
        ]),
      },
    ]);

    if (movingAverageSingle2.length !== 0) {
      setMAChartLineOptions((state) => {
        return {
          ...state,
          xaxis: {
            type: "datetime",
            min: new Date(movingAverageSingle2[0][0]).getTime(),
            max: new Date(
              movingAverageSingle2[movingAverageSingle2.length - 1][0]
            ).getTime(),
          },
          yaxis: {
            title: {
              text: action !== "sum" ? "avg" : "sum",
            },
          },
        };
      });
      setMABrushChartLineSeries([
        {
          name: action !== "sum" ? "avg 2" : "sum 2",
          data: movingAverageSingle2.map((a) => {
            return [a[0], a[1]];
          }),
        },
        {
          name: action !== "sum" ? "avg 3" : "sum 3",
          data: movingAverageSingle4.map((a) => [a[0], a[1]]),
        },
        {
          name: action !== "sum" ? "avg 5" : "sum 5",
          data: movingAverageSingle6.map((a) => [a[0], a[1]]),
        },
      ]);
    } else {
      setMABrushChartLineSeries([]);
    }
    setPrevBrushChartLineSeries([
      {
        name: `${insectState.selected} ${action !== "sum" ? "avg" : "sum"}`,
        data: dualChartData.map((a) => [
          new Date(a.endDate).getTime(),
          action !== "sum" ? a.avg : a.sum,
        ]),
      },
    ]);
  }, [singleChartData, dualChartData, action]);

  useEffect(() => {
    setDates(Helpers.getDatesbyMode(selectedChartMode));
    if (buttonClicked) {
      setButtonClicked(false);
      getLineChart(selectedChartMode, Fields.uId, insectState.selected);
    }
  }, [selectedChartMode]);

  const getLineChart = async (selectedChartMode, field, insect) => {
    setIsLoading(true);
    const lineData = await Helpers.getLineChartData(
      selectedChartMode,
      field,
      insect
    );
    dispatch.Fields.SetChartData({
      type: "dual",
      data: lineData.previousData,
    });
    setIsLoading(false);
  };
  function EMACalc(mArray, date, mRange) {
    var k = 2 / (mRange + 1);
    // first item is just the same as the first item in the input
    let emaArray = [[date[0], mArray[0]]];
    // for the rest of the items, they are computed with the previous one

    for (var i = 1; i < mArray.length; i++) {
      emaArray.push([
        date[i],
        parseFloat(
          (mArray[i] * k + emaArray[i - 1][1] * (1 - k)).toFixed(2) + ""
        ),
      ]);
    }
    return emaArray;
  }
  const movingAverage = (arr = [], date) => {
    const res = [];
    let sum = 0;
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      sum += el;
      count++;
      const curr = sum / count;
      res[i] = [date[i], curr];
    }
    return res;
  };

  const setPPPDate = (date) => {
    dispatch.AppSettings.SetPage('PPP');
    navigate('/ppp');
    dispatch.PPP.SetChartDate(true);
    dispatch.PPP.SetSelectedTreatment(null);
    dispatch.AppSettings.SetNextDates({
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD")
    });
  }
  return (
    <>
      <div className="text-center"></div>
      <div className="text-right">
        <span>
          <button
            className={
              selectedChartMode === Constants.THIS_MONTH
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.THIS_MONTH);
              setButtonClicked(true);
            }}
          >
            This Month
          </button>
          <button
            className={
              selectedChartMode === Constants.LAST_MONTH
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.LAST_MONTH);
              setButtonClicked(true);
            }}
          >
            -1 M
          </button>
          <button
            className={
              selectedChartMode === Constants.LAST_TWO_MONTHS
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.LAST_TWO_MONTHS);
              setButtonClicked(true);
            }}
          >
            -2 M
          </button>
          <button
            className={
              selectedChartMode === Constants.THREE_MONTHS
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.THREE_MONTHS);
              setButtonClicked(true);
            }}
          >
            -3 M
          </button>
          <button
            className={
              selectedChartMode === Constants.SIX_MONTHS
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.SIX_MONTHS);
              setButtonClicked(true);
            }}
          >
            -6 M
          </button>
          <button
            className={
              selectedChartMode === Constants.THIS_YEAR
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.THIS_YEAR);
              setButtonClicked(true);
            }}
          >
            This Year
          </button>
          <button
            className={
              selectedChartMode === Constants.LAST_YEAR
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.LAST_YEAR);
              setButtonClicked(true);
            }}
          >
            -1 Y
          </button>

          <button
            className={
              selectedChartMode === Constants.TWO_YEARS
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.TWO_YEARS);
              setButtonClicked(true);
            }}
          >
            -2 Y
          </button>
          <button
            className={
              selectedChartMode === Constants.FOUR_YEARS
                ? "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md bg-cyan-500 text-white"
                : "mr-2 px-4 py-2 font-semibold text-xs rounded-full shadow-md text-cyan-500 bg-white"
            }
            onClick={() => {
              dispatch.AppSettings.SelectChartMode(Constants.FOUR_YEARS);
              setButtonClicked(true);
            }}
          >
            -4 Y
          </button>
        </span>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex flex-wrap mt-2">
            <div className="w-6/12">
              {/* <label>
                {Fields?.fieldDataName} ,{insectState.selected} (
                {dates.nextStartDate} - {dates.nextEndDate})
              </label> */}
              <div id="wrapper">
                <div id="chart-line2">
                  <Chart
                    options={prevBrushChartOptions}
                    series={prevBrushChartSeries}
                    type="area"
                    height={330}
                  />
                </div>
                <div id="chart-line">
                  <Chart
                    options={prevBrushChartLineOptions}
                    series={prevBrushChartLineSeries}
                    type="bar"
                    height={180}
                  />
                </div>
              </div>
            </div>
            <div className="w-6/12">
              <label>Trends</label>
              <Chart
                options={MAChartLineOptions}
                series={MAChartLineSeries}
                type="line"
                height={330}
              />
            </div>
          </div>
        </>
      )}
      {selectedMode !== "single" && (
        <>
          <label>Previous Data</label>
          <Chart
            options={previousBarOptions}
            series={previousBarSeries}
            type="bar"
            height={350}
          />
          <div className="text-center">
            <label>Location No.</label>
          </div>
        </>
      )}
      {selectedMode !== "single" && <label>Next Data</label>}
      <>
        <Chart
          options={nextBarOptions}
          series={nextBarSeries}
          type="bar"
          height={350}
        />
        <div className="text-center">
          <label>Location No.</label>
        </div>
      </>
    </>
  );
}
