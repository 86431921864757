import * as React from "react";
import { useEffect } from "react";
import { Tooltip, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { LocationsServices } from "../../services";
import * as turf from "@turf/turf";

export default function Save({
  createdMarker,
  createdPolygon,
  deletedMultiplePointsBoundary,
}) {
  const dispatch = useDispatch();
  const createdPoint = useSelector(
    (state) => state.CreatePointandPolygon.createdPoint
  );

  const currentSelectedFieldUID = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldUID
  );
  const currentSelectedFieldId = useSelector(
    (state) => state.FieldDetails.currentSelectedFieldId
  );
  const createdPolygon_ = useSelector(
    (state) => state.CreatePointandPolygon.createdPolygon
  );

  const editedPoint = useSelector(
    (state) => state.CreatePointandPolygon.editedPoint
  );
  const currentLocationNumber = useSelector(
    (state) => state.CreatePointandPolygon.currentLocationNumber
  );
  const editedPolygon = useSelector(
    (state) => state.CreatePointandPolygon.editedPolygon
  );
  const deletedPoint = useSelector(
    (state) => state.CreatePointandPolygon.deletedPoint
  );
  const deletedPolygon = useSelector(
    (state) => state.CreatePointandPolygon.deletedPolygon
  );
  const deletedMultiplePointsBoundary_ = useSelector(
    (state) => state.CreatePointandPolygon.deletedMultiplePointsBoundary
  );
  const selectedFieldCoords = useSelector(
    (state) => state.FieldDetails.selectedFieldCoords
  );

  useEffect(() => {
    if (
      deletedMultiplePointsBoundary_ &&
      deletedMultiplePointsBoundary_.length > 0
    ) {
      dispatch.DisplaySettings.toggleMapEventLoaderDelete(true);
    }
  }, [deletedMultiplePointsBoundary_]);

  const handlePolygonSaveButton = () => {
    if (createdPoint !== null) {
      let parsedInt;
      let stringPart;
      if (selectedFieldCoords !== null) {
        let latest_location = 0;
        selectedFieldCoords.map((item) => {
          const matches = item.location_no.match(/^(\d+)([A-Za-z]+)$/);
          if (matches) {
            const integerPart = parseInt(matches[1], 10);
            if (latest_location < integerPart) {
              latest_location = integerPart;
              stringPart = matches[2];
            }
          }
        });
        parsedInt = latest_location + 1;
      } else {
        parsedInt = 1;
        stringPart = "A";
      }
      // console.log(parsedInt + stringPart, "created Point location number");

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      const data = {
        field_uid: currentSelectedFieldUID,
        points: [
          {
            no: parsedInt + stringPart,
            title: formattedDate,
            wkt: `POINT(${parseFloat(
              createdPoint.getPosition().lat()
            )} ${parseFloat(createdPoint.getPosition().lng())})`,
          },
        ],
      };
      LocationsServices.postPointField(data)
        .then((res) => {
          if (res.status === 200) {
            LocationsServices.getAllCoordinatesOfAfields(
              currentSelectedFieldUID
            )
              .then((res) => {
                const fieldCoords = res.data.locations;
                const fieldCoords_updated = fieldCoords.map((item) => {
                  return {
                    ...item,
                    editable: false,
                    deleteMode: false,
                    editMode: false,
                  };
                });
                if (fieldCoords_updated.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldCoords(
                    fieldCoords_updated
                  );
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });

      createdMarker.setMap(null);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    }
    if (createdPolygon_ !== null) {
      const wktPolygon =
        "POLYGON((" +
        createdPolygon_.map((c) => c.lng + " " + c.lat).join(", ") +
        "))";

      const data = {
        fieldId: parseInt(currentSelectedFieldId),
        wkt: wktPolygon,
      };

      LocationsServices.postPolygonField(data).then((res) => {
        if (res.status === 200) {
          LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
            .then((res) => {
              const polygonGeoms = res.data.map((item) => {
                return {
                  ...item,
                  editable: false,
                  deleteMode: false,
                  editMode: false,
                };
              });
              if (polygonGeoms.length > 0) {
                dispatch.FieldDetails.setSelectedFieldPolygonGeoms(
                  polygonGeoms
                );
              }
            })
            .catch((err) => {
              console.log(err, "err");
            });
        }
      });

      createdPolygon.setMap(null);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    }
    if (editedPoint !== null) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      const data = {
        points: [
          {
            id: editedPoint.id,
            location_no: currentLocationNumber,
            title: formattedDate,
            wkt: `POINT(${parseFloat(editedPoint.newLat)} ${parseFloat(
              editedPoint.newLng
            )})`,
          },
        ],
      };
      LocationsServices.putPointField(data)
        .then((res) => {
          if (res.status === 200) {
            LocationsServices.getAllCoordinatesOfAfields(
              currentSelectedFieldUID
            )
              .then((res) => {
                const fieldCoords = res.data.locations;
                const fieldCoords_updated = fieldCoords.map((item) => {
                  return {
                    ...item,
                    editable: false,
                    deleteMode: false,
                    editMode: false,
                  };
                });
                if (fieldCoords_updated.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldCoords(
                    fieldCoords_updated
                  );
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
      dispatch.CreatePointandPolygon.setEditedPoint(null);
      dispatch.CreatePointandPolygon.setCurrentLocationNumber(null);
      dispatch.DisplaySettings.toggleLocationNumber(false);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    }
    if (editedPolygon !== null) {
      const wktPolygon =
        "POLYGON((" +
        editedPolygon.geometry.map((c) => c.lng + " " + c.lat).join(", ") +
        "))";
      LocationsServices.putPolygonField(editedPolygon.id, {
        wkt: wktPolygon,
      })
        .then((res) => {
          if (res.status === 200) {
            LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
              .then((res) => {
                const polygonGeoms = res.data.map((item) => {
                  return {
                    ...item,
                    deleteMode: false,
                    editable: false,
                    editMode: false,
                  };
                });
                if (polygonGeoms.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldPolygonGeoms(
                    polygonGeoms
                  );
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }
        })
        .catch((err) => {
          console.log(err, "error failure");
        });

      dispatch.CreatePointandPolygon.setEditedPolygon(null);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    }
    if (deletedPoint !== null) {
      LocationsServices.deletePointField(deletedPoint)
        .then((res) => {
          if (res.status === 200) {
            LocationsServices.getAllCoordinatesOfAfields(
              currentSelectedFieldUID
            )
              .then((res) => {
                const fieldCoords = res.data.locations;
                const fieldCoords_updated = fieldCoords.map((item) => {
                  return {
                    ...item,
                    editable: false,
                    deleteMode: false,
                    editMode: false,
                  };
                });
                if (fieldCoords_updated.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldCoords(
                    fieldCoords_updated
                  );
                } else {
                  dispatch.FieldDetails.setSelectedFieldCoords(null);
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
      dispatch.CreatePointandPolygon.setDeletedPoint(null);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    }
    if (deletedPolygon !== null) {
      LocationsServices.deletePolygonField(deletedPolygon)
        .then((res) => {
          if (res.status === 200) {
            LocationsServices.getAllPolygonsOfAfields(currentSelectedFieldId)
              .then((res) => {
                const polygonGeoms = res.data.map((item) => {
                  return {
                    ...item,
                    deleteMode: false,
                    editable: false,
                    editMode: false,
                  };
                });
                if (polygonGeoms.length > 0) {
                  dispatch.FieldDetails.setSelectedFieldPolygonGeoms(
                    polygonGeoms
                  );
                } else {
                  dispatch.FieldDetails.setSelectedFieldPolygonGeoms(null);
                }
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }
        })
        .catch((err) => {
          console.log(err, "error failure");
        });

      dispatch.CreatePointandPolygon.setDeletedPolygon(null);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
    }
    if (deletedMultiplePointsBoundary_ !== null) {
      const locationsInsidePolygon = selectedFieldCoords.filter((location) => {
        const point = turf.point([
          location.geom.coordinates[1],
          location.geom.coordinates[0],
        ]);
        const turfPolygon = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [[]],
          },
          properties: {},
        };
        for (let i = 0; i < deletedMultiplePointsBoundary_.length; i++) {
          turfPolygon.geometry.coordinates[0].push([
            deletedMultiplePointsBoundary_[i].lng,
            deletedMultiplePointsBoundary_[i].lat,
          ]);
        }

        return turf.booleanPointInPolygon(point, turfPolygon);
      });
      if (locationsInsidePolygon && locationsInsidePolygon.length > 0) {
        locationsInsidePolygon.map((location) => {
          LocationsServices.deletePointField(location.id)
            .then((res) => {})
            .catch((err) => {
              console.log(err, "error");
            });
        });
      }

      setTimeout(() => {
        LocationsServices.getAllCoordinatesOfAfields(currentSelectedFieldUID)
          .then((res) => {
            const fieldCoords = res.data.locations;
            const fieldCoords_updated = fieldCoords.map((item) => {
              return {
                ...item,
                editable: false,
                deleteMode: false,
                editMode: false,
              };
            });
            if (fieldCoords_updated.length > 0) {
              dispatch.FieldDetails.setSelectedFieldCoords(fieldCoords_updated);
            } else {
              dispatch.FieldDetails.setSelectedFieldCoords(null);
            }
          })
          .catch((err) => {
            console.log(err, "err");
          });
        deletedMultiplePointsBoundary.setMap(null);
      }, 10000);
      dispatch.CreatePointandPolygon.resetCreatePointandPolygon();
      setTimeout(() => {
        dispatch.DisplaySettings.toggleEditStateAfterCancelOrSave();
      }, 10000);
    }
  };
  return (
    <Tooltip title="Save">
      <IconButton
        onClick={handlePolygonSaveButton}
        sx={{
          "&:hover": { bgcolor: "red" },
        }}
        aria-label="Save"
      >
        <CheckCircleIcon sx={{ color: "black" }} />
      </IconButton>
    </Tooltip>
  );
}
