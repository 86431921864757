import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

export default function LocationNumber() {
  const dispatch = useDispatch();
  const [locationNumber, setLocationNumber] = useState("");
  const showLocationNumber = useSelector(
    (state) => state.DisplaySettings.showLocationNumber
  );
  const currentLocationNumber = useSelector(
    (state) => state.CreatePointandPolygon.currentLocationNumber
  );
  useEffect(() => {
    if (currentLocationNumber) {
      setLocationNumber(currentLocationNumber);
    }
  }, [currentLocationNumber]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: "8px",
        marginLeft: "500px",
        backgroundColor: "white",
        borderRadius: "5px",
        display: showLocationNumber ? "block" : "none",
        height: "80px",
        padding: "10px",
      }}
    >
      <TextField
        id="Location_no"
        label="Location_no"
        value={locationNumber}
        onChange={(event) => {
          setLocationNumber(event.target.value);
          dispatch.CreatePointandPolygon.setCurrentLocationNumber(
            event.target.value
          );
        }}
        InputProps={{ readOnly: false }}
        InputLabelProps={{
          shrink: true,
          position: "above",
          color: "primary",
        }}
      />
    </Box>
  );
}
