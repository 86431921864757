import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Login from "../Pages/Auth/Login";
const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/auth/login" />} />
      <Route path="login" element={<Login />} />
    </Routes>
  );
};
export default PublicRoutes;
