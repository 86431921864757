const CreatePointandPolygon = {
  state: {
    createdPoint: null,
    createdLocationNumber: null,
    createdPolygon: null,
    editedPoint: null,
    editedPolygon: null,
    deletedPoint: null,
    deletedPolygon: null,
    deletedMultiplePointsBoundary: null,
  },
  reducers: {
    // To change the state fields
    setCreatedPoint: (state, payload) => {
      return {
        ...state,
        createdPoint: payload,
      };
    },
    setCreatedLocationNumber: (state, payload) => {
      return {
        ...state,
        createdLocationNumber: payload,
      };
    },
    setCreatedPolygon: (state, payload) => {
      return {
        ...state,
        createdPolygon: payload,
      };
    },
    setEditedPoint: (state, payload) => {
      return {
        ...state,
        editedPoint: payload,
      };
    },
    setEditedPolygon: (state, payload) => {
      return {
        ...state,
        editedPolygon: payload,
      };
    },
    setDeletedPoint: (state, payload) => {
      return {
        ...state,
        deletedPoint: payload,
      };
    },
    setDeletedPolygon: (state, payload) => {
      return {
        ...state,
        deletedPolygon: payload,
      };
    },
    setDeletedMultiplePointsBoundary: (state, payload) => {
      return {
        ...state,
        deletedMultiplePointsBoundary: payload,
      };
    },
    resetCreatePointandPolygon: (state) => {
      return {
        ...state,
        createdPoint: null,
        createdLocationNumber: null,
        createdPolygon: null,
        editedPoint: null,
        editedPolygon: null,
        deletedPoint: null,
        deletedPolygon: null,
        deletedMultiplePointsBoundary: null,
      };
    },
  },
  effcets: (dispatch) => ({}),
};

export default CreatePointandPolygon;
